<style scoped lang="less">
.c_version_final {
  padding: 20px;
  background: #fff;
  box-shadow: @shadow;
  .message {
    padding-top: 30px;
    font-weight: bold;
  }
  .list {
    margin-top: 15px;
    li {
      margin-bottom: 10px;
    }
  }
}
</style>
<template>
  <div class="c_version_final">
    <div class="stu-module-header">
      <div class="stu-module-title">论文终稿</div>
    </div>
    <div v-loading="loading" :style="{ minHeight: height + 'px' }">
      <ul v-if="data" class="list">
        <!-- <li v-for="(v, k) in data" :key="k">{{ k }}：{{ v }}</li> -->
        <li>批次名称：{{ data.batchName }}</li>
        <li>
          论文地址：<el-link
            target="_blank"
            :href="data.eduGraduateDesignStudentVoo.attachmentUrl"
            >查看</el-link
          >
        </li>
      </ul>
      <div v-else class="message">{{ message }}</div>
    </div>
  </div>
</template>

<script>
import { FinalPaper } from "@/libs/api/topic";
export default {
  name: "c_version_final",
  data() {
    return {
      height: window.innerHeight - 260,
      message: null,
      loading: false,
      data: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      FinalPaper({
        // studentId: this.user.id,
        // batchId: this.user.batchId,
        paperStatus: 2,
      })
        .then((res) => {
          this.loading = false;
          this.data = res.data;
        })
        .catch((error) => {
          const { message } = error;
          this.message = message;
          this.loading = false;
        });
    },
  },
};
</script>